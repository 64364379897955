/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('whatwg-fetch') // if it's gatsby v2 - https://gatsby.app/no-mixed-modules
require("bootstrap/dist/css/bootstrap.min.css")
require("bootstrap/dist/js/bootstrap.min.js")

exports.onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
}
